import { React, useState } from 'react';
import './Submit.css';
import { useLocation, useHistory } from 'react-router-dom'
import { ThreeDots  } from "react-loader-spinner";
import axios from 'axios'
import https from 'https'
import tcert from "../assets/certs/test/vppcert.pem"
import tkey from "../assets/certs/test/vppcert.key"
import tca from "../assets/certs/test/vppcertca.pem"
import { useOktaAuth } from '@okta/okta-react';
import Certificate from '../components/Certificate';

const Submit = ({ location, isAdmin }) => {
    const history = useHistory()
    const { authState, oktaAuth } = useOktaAuth();

    const [form, setForm] = useState({
        first: '',
        last: '',
        company: '',
    });
    const [loading, setLoading] = useState(false);
    // const {state} = useLocation();
    
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    if(location == undefined) {
        return (<div className='container'><h1>Submission not allowed without watching the video.</h1></div>);
    }

    // var {location} = state;

    const handleChange = (event) => {
        setForm({
          ...form,
          [event.target.id]: event.target.value,
        });
    };

    const clearState = () => {
        setForm({
            first: null,
            last: null,
            company: null
        })
    }

    // Handle post request
    const handleSubmit = async (e) => {
        e.preventDefault();

        if((form.first == null || form.first.length <= 0) || 
           (form.last == null  || form.last.length <= 0)) {
            alert("First and Last Name is required.")
            return;
        }

        setLoading(true)

        var postPayload = {
            CYear: new Date().toISOString().substring(0, 4),
            CDate: new Date().toISOString(),
            FName: form.first.toLowerCase(),
            LName: form.last.toLowerCase(),
            Loc: location.toLowerCase(),
            Company: form.company.toLowerCase()
        }

        // const httpsAgent = new https.Agent({
        //     ca: await readFileAsync(tca),
        //     cert: await readFileAsync(tcert),
        //     key: "key",
        // })

        // console.log(httpsAgent.Agent)
        // console.log(httpsAgent.key)

        // const options = {
        //     url: process.env.REACT_APP_VPP_LAMBDA_API_URL + 'submitentry',
        //     method: "POST",
        //     httpsAgent: httpsAgent,
        //     headers: {
        //         'Content-Type': 'application/txt;charset=UTF-8'
        //     },
        //     data: JSON.stringify(postPayload)
        // };

        // const result = await axios.get('https://vppcertapi-t.ephillips66.com:443/api/submitentry1', { httpsAgent });

        // console.log(result)

        // const instance = axios.create({
        //     httpsAgent: new https.Agent({  
        //         rejectUnauthorized: false,
        //         ca: [await readFileAsync(tca)],
        //         cert: await readFileAsync(tcert),
        //         key: await readFileAsync(tkey),
        //     })
        //   });

        //   instance.get('https://vppcertapi-t.ephillips66.com:443/api/submitentry1');

        // const options = {
        //     hostname: 'vppcertapi-t.ephillips66.com',
        //     port: 443,
        //     path: '/api/submitentry1',
        //     ca: [
        //         await readFileAsync(tca),
        //     ],
        //     rejectUnauthorized: false,
        //     key: await readFileAsync(tkey),
        //     cert: await readFileAsync(tcert),
        //     passphrase: 'world'
        // };

        // const req = https.request(options, (res) => {
        //     console.log('Status Code : ', res.statusCode);
        //     console.log('Headers : ', res.headers)
                      
        //     res.on('data', (data)=> {
        //       console.log(data)
        //     })
        // })

        // req.on('error', (err) => {
        //     console.log(err);
        // })

        // req.end();

        // axios(options).then(response => {
        //     setLoading(false);
        //     if(response.status === 200) {
        //         clearState();
        //         history.push({pathname: '/certificate', state: {certificate: postPayload}})
        //     } else {
        //         alert("Submission failed. Retry after few minutes.")
        //     }
        // }).catch(err => {
        //     console.log(err);
        //     setLoading(false);
        //     alert("Submission failed. Retry after few minutes.")
        // });

        // console.log(process.env.REACT_APP_VPP_LAMBDA_API_URL)
        console.log(window.location.origin)

        fetch(process.env.REACT_APP_VPP_LAMBDA_API_URL + (isAdmin ? 'adminsubmit' : 'usersubmit'), {
            method: 'POST',
            // credentials: 'include',
            body: JSON.stringify(postPayload),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': isAdmin ? ((authState != null && authState.isAuthenticated) ? oktaAuth.getIdToken() : "none") : "none",
                'hostname': window.location.origin,
                'xforwardedfor': 'proto'
            },
        })
        .then((res) => {
            setLoading(false);
            if(res.status === 200) {
                clearState();
                setModalData(postPayload)
                openFromParent()

                // history.push({pathname: '/certificate', state: {certificate: postPayload}})
            } else {
                alert("Submission failed. Retry after few minutes.")
            }
        })
        .catch((err) => {
            setLoading(false);
            alert("Submission failed. Retry after few minutes.")
        });
    };

    const readFileAsync = async (url) => {
        await fetch(url)
            .then((res) => res.text())
            .then((text) => {
                console.log(text)
                return text
            })
            .catch((e) => {
                console.error(e)
                return
            });
    }

    function openFromParent() {
        setIsOpen(true);
    }

    function handleCloseModal(event, data) {
        setIsOpen(false);
        if (isAdmin == false) {
            history.goBack()
        }
    }

    return (
        <div className='sub_container'>
            <div className="sub_loader">
                {loading == true && (<ThreeDots color="red" radius={"8px"} />)}
            </div>

            <p className='sub_title'>Phillips 66 Safety Orientation</p>
            <p>In order to get credit for watching the video, please: </p>
            <ul>
                <li>Fill in the items below.</li>
                <li>Click "Submit and Print."</li>
                <li>Bring your certificate to the respective Phillips 66 location.</li>
            </ul>
            <br/>
            <form>
                <div className="sub_formlabel">
                    First Name *:
                </div>
                <input className="sub_formelement" id="first" type="text" value={form.first || ""} onChange={handleChange} />

                <div className="sub_formlabel">                        
                    Last Name *:
                </div>
                <input className="sub_formelement" id="last" type="text" value={form.last || ""} onChange={handleChange} />
        
                <div className="sub_formlabel">
                    Company:
                </div>
                <input className="sub_formelement" id="company" type="text" value={form.company || ""} onChange={handleChange} />

                <div className="sub_formlabel">
                    Location:
                </div>
                <input className="sub_formelement" type="text" value={location} disabled/>

                <div className="buttoncontainer">
                    <input className='sub_submit' type="submit" value="Submit" onClick={handleSubmit} />
                </div>
            </form>

            <Certificate
                modalData={modalData}
                IsModalOpened={modalIsOpen}
                onCloseModal={handleCloseModal}
            />
        </div>
    );
};

export default Submit;