import { React, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { ThreeDots  } from "react-loader-spinner";
import Submit from '../components/Submit';

const UserSubmit = () => {
    const history = useHistory()
    const {state} = useLocation();

    if(state == undefined) {
        return (<div className='container'><h1>Submission not allowed without watching the video.</h1></div>);
    }

    var {location} = state;

    return (
        <>
            <Submit location={location} isAdmin={false}/>
        </>        
  
    );
};

export default UserSubmit;