import { React, useState, useMemo, useEffect } from 'react';
import './Certificate.css';
import { useLocation, useHistory } from 'react-router-dom'
import Modal from 'react-modal';

const Certificate = (props) => {
    // const {state} = useLocation();
    // const history = useHistory();

    // useEffect(() => {
    //     return () => {
    //             // Code here will run when back button fires. Note that it's after the `return` for useEffect's callback; code before the return will fire after the page mounts, code after when it is about to unmount.
    //             if (history.location.pathname.includes("usersubmit")) {
    //                 history.replace("/?location=" + certificate.Loc)
    //             }
    //     }
    // })    

    const certificate = props.modalData;

    const convertDateTime = (utcDate) => {
        var date = new Date(utcDate)
        var localdate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
        localdate = new Date(localdate.setFullYear(localdate.getFullYear() + 1));
        return localdate.toISOString().split("T")[0];
    } 

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          width: '60%',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: '0 0 10px #ccc',
        }
    };

    function onModalClose(event) {
        props.onCloseModal(event);
    }

    function isInputValid(input) {
        if (input != undefined && input != null && input.length > 0) 
            return true
        else
            return false
    }

    return (
        <Modal
            isOpen={props.IsModalOpened}
            style={customStyles}
            ariaHideApp={false}
        >                

            {certificate == undefined && 
                <div className='cer_container'><h1>Please watch the safety orientation video to print the certificate.</h1>
                    <div className="cer_buttoncontainer">
                        <input className='cer_print' type="submit" value="Close" onClick={e => onModalClose(e)} />
                    </div>
                </div>
            }

            {certificate != undefined && 
                <div className='cer_container'>
                    <p className='cer_title'>Phillips 66 Visitor's Safety Orientation</p>

                    <div class="cer_formcontainer">
                        <b class="cer_formlabel">First Name</b>
                        <p class="cer_formelement">{isInputValid(certificate.FName) && certificate.FName[0].toUpperCase() + certificate.FName.slice(1)}</p> 
                    </div>

                    <div class="cer_formcontainer">
                        <b class="cer_formlabel">Last Name</b> 
                        <p class="cer_formelement">{isInputValid(certificate.LName) && certificate.LName[0].toUpperCase() + certificate.LName.slice(1)}</p>
                    </div>

                    <div class="cer_formcontainer">
                        <b class="cer_formlabel">Company</b> 
                        <p class="cer_formelement">{isInputValid(certificate.Company) && certificate.Company[0].toUpperCase() + certificate.Company.slice(1)}</p> 
                    </div>

                    <div class="cer_formcontainer">
                        <b class="cer_formlabel">Location</b> 
                        <p class="cer_formelement">{isInputValid(certificate.Loc) && certificate.Loc[0].toUpperCase() + certificate.Loc.slice(1)}</p>      
                    </div>    

                    <div class="bold">Has completed the {isInputValid(certificate.Loc) && certificate.Loc[0].toUpperCase() + certificate.Loc.slice(1)} Visitor's Safety Orientation.</div>
                    <div class="semibold">Valid until {convertDateTime(certificate.CDate)}</div>

                    <div className="cer_buttoncontainer">
                        <input className='cer_print' type="submit" value="Print" onClick={()=>{
                            window.print()}} />                
                        <input className='cer_print' type="submit" value="Close" onClick={e => onModalClose(e)} />
                    </div>                
                </div>
            }            
        </Modal>
    );
};

export default Certificate;