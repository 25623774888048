import { React, useRef, useEffect } from 'react';
import './Home.css';
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string'
import LocationDef  from '../Global';

const Home = () => {
    const {search} = useLocation();
    const history = useHistory()
    const videoRef = useRef(null);
    const location = queryString.parse(search).location;

    const allLocations = Object.keys(LocationDef).map(function(key){
        return LocationDef[key];
    })

    useEffect(() => {
        var supposedCurrentTime = 0;

        if (videoRef.current) {
          // Prevent seeking by listening to the "seeking" event
          const handleSeeking = () => {
            if (videoRef.current) {
              videoRef.current.currentTime = 0; // Reset the video to the beginning
            }
          };
    
          videoRef.current.addEventListener('timeupdate', () => {
            if (!videoRef.current.seeking) {
                supposedCurrentTime = videoRef.current.currentTime;
            }
          });

          // Add the "seeking" event listener when the component mounts
          videoRef.current.addEventListener('seeking', () => {
            var delta = videoRef.current.currentTime - supposedCurrentTime;
            if (Math.abs(delta) > 0.01) {
                videoRef.current.currentTime = supposedCurrentTime;
            }
          });

          videoRef.current.addEventListener('ended', function() {
            // reset state in order to allow for rewind
              supposedCurrentTime = 0;
              history.push({pathname: '/usersubmit', state: {location: _location}})
          });
    
        //   // Remove the event listener when the component unmounts
        //   return () => {
        //     if (videoRef.current) {
        //       videoRef.current.removeEventListener('seeking');
        //       videoRef.current.removeEventListener('timeupdate');
        //       videoRef.current.removeEventListener('ended');
        //     }
        //   };
        }
    }, []);

    if(location == undefined) {
        return (<div className='container'><h1>Location is missing in the URL.</h1></div>);
    }

    const _location = location.toLowerCase().replace(/['"]+/g, '');

    if (!allLocations.includes(_location)) {
        return (<div className='container'><h1>Location is invalid.</h1></div>);
    }

    return (
        <div className='home_outercontainer'>
            <div className='home_innercontainer'>
                <p className='home_title'>
                    {_location == LocationDef.Houston && "Phillips 66 HQ Safety Orientation"}
                    {_location == LocationDef.Bartlesville && "Phillips 66 Bartlesville Safety Orientation"}
                    {_location == LocationDef.PRC && "Phillips 66 Research Center Safety Orientation"}
                    {_location == LocationDef.Bayway && "Phillips 66 Bayway Safety Orientation"}
                </p>
                <div className='home_videocontainer'>
                    {/* {_location == LocationDef.Houston && <video width="45%" ref={videoRef}  disablePictureInPicture controls>
                        <source src={process.env.PUBLIC_URL + '/videos/visitorsafety.mp4'} type="video/mp4"/> */}
                        {_location == LocationDef.Houston && 
                                        <video width="45%" ref={videoRef}  disablePictureInPicture controls>
                                            <source src={process.env.PUBLIC_URL + '/videos/houston.mp4'} type="video/mp4"/>               
                                        </video>}

                        {_location == LocationDef.Bartlesville && 
                                        <video width="45%" ref={videoRef}  disablePictureInPicture controls>
                                            <source src={process.env.PUBLIC_URL + '/videos/bartlesville.mp4'} type="video/mp4"/>               
                                        </video>}

                        {_location == LocationDef.PRC && 
                                        <video width="45%" ref={videoRef}  disablePictureInPicture controls>
                                            <source src={process.env.PUBLIC_URL + '/videos/prc.mp4'} type="video/mp4"/>               
                                        </video>}

                        {_location == LocationDef.Bayway && 
                                        <video width="45%" ref={videoRef}  disablePictureInPicture controls>
                                            <source src={process.env.PUBLIC_URL + '/videos/bayway.mp4'} type="video/mp4"/>               
                                        </video>}

                        <p className='home_sidetitle'>
                            {_location == LocationDef.Houston && 
                                "Please view the Phillips 66 Headquarters Safety Orientation in its entirety to ensure your visitor registration."}
                            {_location == LocationDef.Bartlesville && 
                                "Please view the Phillips 66 Bartlesville Safety Orientation in its entirety to ensure your visitor registration."}
                            {_location == LocationDef.PRC && 
                                "Please view the Phillips 66 Research Center Safety Orientation in its entirety to ensure your visitor registration."}
                            {_location == LocationDef.Bayway && 
                                "Please view the Phillips 66 Bayway Safety Orientation in its entirety to ensure your visitor registration."}
                        </p>
                </div>
            </div>
            <p className='home_copyright'>© Phillips66</p>
            {/* <button type="button" onClick={()=>history.push({pathname: '/usersubmit', state: {location: _location}})}>Submit</button> */}
        </div>
    );
};

export default Home;