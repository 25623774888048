import { React, useState, useMemo, useEffect } from 'react';
import './Report.css';
import { useHistory, useLocation } from 'react-router-dom'
import { useTable } from 'react-table';
import queryString from 'query-string'
import { ThreeDots  } from "react-loader-spinner";
import LocationDef  from '../Global';
import { render } from '@testing-library/react';
import Certificate from '../components/Certificate';
import { useOktaAuth } from '@okta/okta-react';

sessionStorage.setItem("STATE", JSON.stringify({
    first: '',
    last: '',
    company: '',
    start: null,
    end: null,
    location: LocationDef.Houston
}));

const Report = (props) => {
    const history = useHistory()
    const { authState, oktaAuth } = useOktaAuth();

    const [form, setForm] = useState(JSON.parse(sessionStorage.getItem("STATE")));

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [renderSelect, setRenderSelect] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    // const {search} = useLocation();
    // const location = queryString.parse(search).location

    const allLocations = Object.keys(LocationDef).map(function(key){
        return LocationDef[key];
    })

    useEffect(() => {
        if(form.start == null) {
            form.location = "DEFAULT"
            setRenderSelect(true);
        } else {
            setRenderSelect(true);
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem("STATE", JSON.stringify(form));
    }, [form]);

    const handleChange = (event) => {

        setForm({
          ...form,
          [event.target.id]: event.target.value,
        });
    };

    const columns = useMemo(
        () => [
          {
            Header: ' ',
            accessor: '',
            Cell: ({ row }) => (
                <button value='' onClick={() => 
                    {
                        // history.push({pathname: '/certificate', state: {certificate: row.original}})
                        setModalData(row.original)
                        openFromParent()
                    }}>
                  View
                </button>
            )
          },
          {
            Header: 'Certified Date',
            accessor: 'CDate', // accessor is the "key" in the data
            Cell: props => <div>{convertDateTime(props.value)}</div>
          },
          {
            Header: 'First Name',
            accessor: 'FName', // accessor is the "key" in the data
          },
          {
            Header: 'Last Name',
            accessor: 'LName', // accessor is the "key" in the data
          },
          {
            Header: 'Company',
            accessor: 'Company', // accessor is the "key" in the data
          }
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data })

    // if(location == undefined) {
    //     return (<div className='container'><h1>Location is missing in the URL.</h1></div>);
    // }

    // const _location = location.toLowerCase().replace(/['"]+/g, '');

    // if (!allLocations.includes(_location)) {
    //     return (<div className='container'><h1>Location is invalid.</h1></div>);
    // }

    // Handle post request
    const handleSubmit = (e) => {
        e.preventDefault();

        if(form.location == "DEFAULT") {
            alert("Please select a location.")
            return;
        }

        if((form.start == null || form.start == "") && (form.end == null || form.end == "") && (form.first == null || form.first == "") && (form.last == null || form.last == "")) {
            alert("Date/Name is required for search.")
            return;
        }

        if(((form.start != null && form.start != "") && (form.end == null || form.end == "")) || ((form.start == null || form.start == "") && (form.end != null && form.end != ""))) {
            alert("Both start and end date is required to search by date.")
            return;
        }

        if(form.start != null && form.start != "" && form.end != null && form.end != "") {
            if(new Date(form.end) < new Date(form.start)) {
                alert("Start date cannot be greater than end date.")
                return;
            }

            if(form.start.substring(0 ,4) !== form.end.substring(0 ,4)) {
                alert("Start date and end date should be for the same year for better query performance.")
                return;
            }
        }   

        setLoading(true)

        fetch(process.env.REACT_APP_VPP_LAMBDA_API_URL + 'dbquery', {
            method: 'POST',
            // credentials: 'include',
            body: JSON.stringify({
                StartDate: (form.start != null && form.start != "") ? (form.start + "T00:00:00") : "",
                EndDate: (form.end != null && form.end != "") ? (form.end + "T23:59:59") : "",
                Company: form.company,
                FName: form.first,
                LName: form.last,
                Loc: form.location
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': (authState != null && authState.isAuthenticated) ? oktaAuth.getIdToken() : "none" ,
                'hostname': window.location.origin,
                'xforwardedfor': 'proto'
            },
        })
        .then((res) => {
            setLoading(false)
            if(res.status == 200) {
                res.json().then((data) => {
                    setData(data)
                })
            } else {
                alert("An error occurred while processing your request.")
            }
        })       
        .catch((err) => {
            setLoading(false)
            alert("An error occurred while processing your request.")
        });
    };

    const convertDateTime = (utcDate) => {
        var date = new Date(utcDate)
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
    }

    function openFromParent() {
        setIsOpen(true);
    }

    function handleCloseModal(event, data) {
        setIsOpen(false);
    }

    return (
        <div className='rp_container'>
            <div className="rp_loader">
                {loading == true && (<ThreeDots color="red" radius={"8px"} />)}
            </div>

            <p className='rp_title'>Phillips 66 Visitor's Safety Orientation (SEARCH)</p>
            <br/>
            
            <p className='rp_subtitle'>Enter in desired search criteria and press Search to view certified visitors.</p>
            <br/>
            
            <form className='rp_form'>
                <div className="rp_formlabel">
                    Location *:
                </div>

                { renderSelect && <select className="rp_formelement" id="location" onChange={handleChange} value={form.location || "DEFAULT"}>
                        <option value={"DEFAULT"} disabled>Choose a location ...</option>
                        {allLocations.map((location) => <option value={location}>{location}</option>)}
                    </select>
                }

                <div className="rp_formlabel">
                    First Name:
                </div>
                <input className="rp_formelement" id="first" type="text" value={form.first || ""} onChange={handleChange} />

                <div className="rp_formlabel">                        
                    Last Name:
                </div>
                <input className="rp_formelement" id="last" type="text" value={form.last || ""} onChange={handleChange} />          
                
                <div className="rp_formlabel">
                    Start Date:
                </div>
                <input className="rp_formelement" id="start" type="date" value={form.start || ""} onChange={handleChange} />

                <div className="rp_formlabel">
                    End Date:
                </div>
                <input className="rp_formelement" id="end" type="date" value={form.end || ""} onChange={handleChange} />                
        
                <div className="rp_formlabel">
                    Company:
                </div>
                <input className="rp_formelement" id="company" type="text" value={form.company || ""} onChange={handleChange} />

                {/* <input className="rp_formelement" type="text" value={_location} disabled/> */}

                <div className="rp_buttoncontainer">
                    <input className='rp_submit' type="submit" value="Search" onClick={handleSubmit} />
                </div>
            </form>

            <div className="rp_tablecontainer">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <Certificate
                modalData={modalData}
                IsModalOpened={modalIsOpen}
                onCloseModal={handleCloseModal}
            />
        </div>
    );
};

export default Report;