import { React, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { ThreeDots  } from "react-loader-spinner";
import Submit from '../components/Submit';
import queryString from 'query-string'
import LocationDef  from '../Global';
import { useOktaAuth } from '@okta/okta-react';

const AdminSubmit = () => {
    const {search} = useLocation();
    const location = queryString.parse(search).location;

    const allLocations = Object.keys(LocationDef).map(function(key){
        return LocationDef[key];
    })
    
    if(location == undefined) {
        return (<div className='container'><h1>Location is missing in the URL.</h1></div>);
    }

    if (!allLocations.includes(location)) {
        return (<div className='container'><h1>Location is invalid.</h1></div>);
    }

    return (
        <>
            <Submit location={location} isAdmin={true}/>
        </>         
    );
};

export default AdminSubmit;