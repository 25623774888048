// Components/NavBar.js
import { Link } from 'react-router-dom';
import './NavBar.css';
import shield from '../assets/images/shield.png'; // with import

const NavBar = () => {
 return (
    <nav>
       <img src={shield} />

       {/* <ul>
          <li>
             <Link to="/">Home</Link>
          </li>
          <li>
             <Link to="/about">About</Link>
          </li>
          <li>
             <Link to="/products">Products</Link>
          </li>
       </ul> */}
    </nav>
 );
};

export default NavBar;