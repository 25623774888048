import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, withRouter, useHistory  } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';

import Home from "./pages/Home";
import Submit from "./components/Submit";
import Report from "./pages/Report";
import UserSubmit from "./pages/UserSubmit";
import AdminSubmit from "./pages/AdminSubmit";
import NavBar from './components/NavBar';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_VPP_OKTA_ISSUER, 
  clientId: process.env.REACT_APP_VPP_OKTA_CLIENTID,
  redirectUri: window.location.origin + '/login/callback',
  scopes:['openid','profile']
});

const App = () => {  
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <>      
      <NavBar />

      <div style={{paddingTop: "100px", height: "0px"}}></div>

      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Route path="/" exact={true} component={Home} />
          <Route path="/usersubmit" component={UserSubmit} />
          <SecureRoute path="/submit" component={AdminSubmit} />
          <SecureRoute path="/report" component={Report} />
          <Route path="/login/callback" component={LoginCallback} />
      </Security>
    </>
  );
}

const AppWithRouterAccess = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouterAccess;